import Sheep from '..//Assests/Sheep.jpg'
import { useNavigate } from 'react-router-dom';


const Goat = () => {
    const navigate = useNavigate()
    function goToPayment(){
        navigate('/Payment')
    }
    return (
        

        <div className='container' >
            <h1 align="center"> Goat / Sheep Tourist Attraction Details Read Below </h1>
            <p className='p' align="center"> Guided Tour With Tourist Guide Rs,1000/- Click Here , Foriegn Tourist $10/- Virtual Tour Through Video Call </p>
                <center><button jutify-content="center" className='btn' onClick={goToPayment}>Book Tourist Attraction Visit Now</button></center><br/>
               
            
         <center><img src={Sheep} width={400} height={400} alt="error loading"></img></center> <br/><br/>
         <p className='p' align="center"> The goat or domestic goat  is a domesticated species of goat-antelope typically kept as livestock. It was domesticated from the wild goat of Southwest Asia and Eastern Europe. The goat is a member of the animal family Bovidae and the tribe Caprini, meaning it is closely related to the sheep. There are over 300 distinct breeds of goat. It is one of the oldest domesticated species of animal, according to archaeological evidence that its earliest domestication occurred in Iran at 10,000 calibrated calendar years ago.
<br/><br/>
Goats have been used for milk, meat, fur, and skins across much of the world. Milk from goats is often turned into goat cheese.

In 2011, there were more than 924 million goats living in the world, according to the UN Food and Agriculture Organization. </p>
        
        <p className='p' align="center"> Support Us To Maintain This Tourist Attraction Website, Create Awareness Towards Animals & Birds, Contribute Money Please, Your Small Regular Monthly Or Weekly Money Contribution As Low As A Tea Money Can Help Us Keep This Site Active, So Please Click On Pay Now And Pay Us, Thank You  </p>
        
        <p className='p' align="center"> If You Want To Visit This Place, I Will Share Google Map Location on WhatsApp After You Pay Me Rs,100/- Per Location, Send Me Screen Shot of Payment, My WhatsApp Number On Pay Now Button I Will Send You Location Click Pay Now </p>
        
        <h2 align="center"> 500+ Goat / Sheeps Videos On Youtube Channel Link Below </h2>
         <center><button>
            <a className='youtube' href="https://www.youtube.com/playlist?list=PLO2LDzjQ7TtXDj_3tCH3IV5rSfmCHsb1Z">Go To Youtube </a>
            </button></center>
        
        
              </div>
        
       
    )
}
export default Goat