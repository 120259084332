import Amazon from '..//Assests/Amazon.jpg'
import '../App.css';
import japan from '..//Assests/japan.png'
import { useNavigate } from 'react-router-dom';
const Japan = () => {
    const navigate = useNavigate()
    function Payment(){
        navigate('/Payment')
    }
    return  (
        <>
        <center> <div>
       <br/> <h4> Japan Amazon Shop</h4>
        <h4>Shinto Religion & Buddhism Religion Gods Will Bless You , Please Shop With My Below Amazon Affiliate Link. Also Donate To WildLife <button jutify-content="center" className='btn' onClick={Payment}>WildLife Donation Request Click Me</button></h4>
        
        <br/><img src={japan} alt='error'></img>
    </div></center>
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Buy Any Products From Amazon </h3>
                    
                    <a href="https://amzn.to/3QQjpUj">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> towel </h3>
                    
                    <a href="https://amzn.to/44GucWM">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Water Filter Cartridge</h3>
                    
                    <a href="https://amzn.to/3UKWlaG">Buy Now</a>
                </div>
               
            </div>
    
        </div>
         <div className="container">
         <div className="content-section">
         <div className="card">
                 <img src={Amazon} alt="error"></img>
                 <h3> Air Conditioner </h3>
                 
                 <a href="https://amzn.to/4aq6jnF">Buy Now</a>
             </div>
             <div className="card">
                 <img src={Amazon} alt="error"></img>
                 <h3> Air Conditioner </h3>
                 
                 <a href="https://amzn.to/4aGBGL7">Buy Now</a>
             </div>
             <div className="card">
                 <img src={Amazon} alt="error"></img>
                 <h3> Cold Air Fan</h3>
                 
                 <a href="https://amzn.to/4bAvtBi">Buy Now</a>
             </div>
            
         </div>
 
     </div>
     <div className="container">
         <div className="content-section">
         <div className="card">
                 <img src={Amazon} alt="error"></img>
                 <h3> Oukital Mobile </h3>
                 
                 <a href="https://amzn.to/3V2wV9Y">Buy Now</a>
             </div>
             <div className="card">
                 <img src={Amazon} alt="error"></img>
                 <h3> Doog Phone </h3>
                 
                 <a href="https://amzn.to/3V6vSpF">Buy Now</a>
             </div>
             <div className="card">
                 <img src={Amazon} alt="error"></img>
                 <h3> Ulefone Mobile</h3>
                 
                 <a href="https://amzn.to/3K8KDC4">Buy Now</a>
             </div>
            
         </div>
 
     </div>
     <div className="container">
         <div className="content-section">
         <div className="card">
                 <img src={Amazon} alt="error"></img>
                 <h3> Acer Laptop </h3>
                 
                 <a href="https://amzn.to/3UONwN2">Buy Now</a>
             </div>
             <div className="card">
                 <img src={Amazon} alt="error"></img>
                 <h3> MSI Laptop </h3>
                 
                 <a href="https://amzn.to/3yxETyS">Buy Now</a>
             </div>
             <div className="card">
                 <img src={Amazon} alt="error"></img>
                 <h3> Galleria Laptop</h3>
                 
                 <a href="https://amzn.to/4asJv6W">Buy Now</a>
             </div>
            
         </div>
 
     </div>
     <center><h1>At Least Buy Dog Food From My Amazon Affiliate Link</h1></center>
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Nutro Supremo Dog Food 6.6Lb 3Kg </h3>
                    
                    <a href="https://amzn.to/3wHPua6">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Dog Food Grandile 6.4lbs 2.9kg </h3>
                    
                    <a href="https://amzn.to/4apzFCK">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3>Dog Food Japan Pet Food</h3>
                    
                    <a href="https://amzn.to/44PoZfi">Buy Now</a>
                </div>
               
            </div>
    
        </div>
    
     
     </>
    )
    }
    export default Japan