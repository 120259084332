const Disclaimer = () => {
    return(
        <div>
          <center>  <h1>
            <br/><br/>

**Disclaimer**
<br/><br/>
**Last Updated: [26-07-2024]**
<br/><br/>
**1. Affiliate Disclaimer**
<br/><br/>
[TouristAttraction.shop] (the "Site") participates in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com. As an Amazon Associate, we earn from qualifying purchases made through links on our Site. This means that if you click on a link and make a purchase, we may earn a commission at no additional cost to you.
<br/><br/>
**2. Content Accuracy**
<br/><br/>
While we strive to provide accurate and up-to-date information on our Site, we make no warranties or representations about the accuracy, reliability, completeness, or timeliness of any content. The information provided on our Site is for general informational purposes only and should not be construed as professional advice.
<br/><br/>
**3. Product Endorsement**
<br/><br/>
The presence of affiliate links on our Site does not imply endorsement of the linked product or service. We do not guarantee the quality or effectiveness of any product or service you purchase through our affiliate links. Your purchase decisions should be based on your own research and judgment.
<br/><br/>

**4. No Warranty**
<br/><br/>
Our Site and all content, products, and services provided through it are offered on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, regarding the operation or availability of our Site, or the information, content, materials, or products included on it. To the fullest extent permitted by law, we disclaim all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
<br/><br/>
**5. Limitation of Liability**
<br/><br/>
In no event will [TouristAttraction], its affiliates, or its partners be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, or other intangible losses, arising from or related to your use of our Site or any content, products, or services provided through it.
<br/><br/>
**6. Third-Party Links**
<br/><br/>
Our Site may contain links to third-party websites or services that are not owned or controlled by [TouristAttraction]. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites. You acknowledge and agree that [TouristAttraction] is not responsible or liable, directly or indirectly, for any damage or loss caused by or in connection with the use of or reliance on any third-party content, goods, or services.
<br/><br/>
**7. Changes to This Disclaimer**
<br/><br/>
We may update this disclaimer from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this disclaimer periodically to stay informed about how we are protecting your information and to understand your rights and obligations.
<br/><br/>
**8. Contact Us**
<br/><br/>
If you have any questions or concerns about this disclaimer or our affiliate practices, please contact us at:
<br/><br/>
[Tourist Attraction]  
[Address :- Bengaluru]  
[Email :- donateforpoorhungry@gmail.com]  
            </h1></center>
        </div>
    )
}
export default Disclaimer