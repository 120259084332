// import MOHAMMEDSUHAILAHMED from '..//Assests/MOHAMMEDSUHAILAHMED.jpg'

import Web1 from '..//Assests/web1.jpg'
import Cowss  from '..//Assests/cowss.jpg'
import Pigeon from '..//Assests/Pigeon.jpg'
import paypal from '..//Assests/paypal.png'
import Eagle from '..//Assests/Eagle.jpg'
import Sheep from '..//Assests/Sheep.jpg'

const Payment = () => {
    return (
            <>
        <div className='headlines'>
      <center><h2>Till Now $700 Received , And We Provided Food , Shelter For Animals And Birds</h2></center>
      </div>
      <div className='anchortag'>
      <center> <p> CheckOut Our Work On Youtube</p></center>
      <center> <button>  <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtVE5AQl2wSGaNQhUn63qPdi'>1. Dogs Videos Click Me</a><br/></button></center><br/>
       <center><button> <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtV_zlwR8IWqBknxXUrLIxAK'>2. Cow Videos Click Me</a><br/></button></center><br/>
       <center><button> <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtX-EuiKLwOQcRceAr2Ue40y'>3. Pigeons Videos Click Me</a><br/></button></center><br/>
       <center> <button><a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtWL_CVwJTCp9FXmwE1WM8Yg'>4. Eagle Videos Click Me</a><br/></button></center><br/>
       <center><button> <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtXDj_3tCH3IV5rSfmCHsb1Z'>5. Sheep Videos Click Me</a><br/></button></center><br/>
       <center> <button> <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtWn8UjdtyZcmbNKMMrRLVBt'>6. WildLife Videos Click Me</a><br/></button></center><br/>
      </div>
            <div className='text'>
            <center><h1> If You Want To Visit This Places Write To Us At donateforpoorhungry@gmail.com</h1></center>
      <center><h2>Donate For WildAnimals & Birds </h2></center>
      </div>
      <div className='text1'>

      <center><h1>Its Your Time Now To Donate For This Noble Cause , Your Small Contribution As Low As $1 Will Help Keep Wild Animals And Birds Safe</h1></center>
      <center><h1> Scan And Pay By Paypal Or You Can Pay By Below Bank Trasfer Through Paypal</h1></center><br/>
      <center><img src={paypal} alt='error loading'></img></center><br/>
      </div>
      
        <div className='Row'>

      
      <div className='W'>
        <img src={Web1} alt='error'></img>

      </div>
        <div className='W'>
        <img src={Cowss} alt='error'></img>
        
      </div>
      <div className='W'>
        <img src={Pigeon} alt='error'></img>
        
      </div>
      </div>
      
      <div className='Row'>

      
<div className='W'>
  <img src={Sheep} alt='error'></img>

</div>
  <div className='W'>
  <img src={Eagle} alt='error'></img>
  
</div>

</div>
       
        </>
    )}
    export default Payment