const PrivacyPolicy= ()=>{
    return(
        <div>
         <center>   <h1>
            <br/><br/>
**Privacy Policy**
<br/><br/>
**Last Updated: [26-07-2024]**
<br/><br/>
Welcome to [TouristAttraction.shop] (referred to as "we," "our," or "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website [TouristAttraction.shop] (the "Site") and interact with our Amazon Affiliate business.
<br/><br/>
**1. Information We Collect**
<br/><br/>
**Personal Information**  <br/><br/>
We may collect personal information that you voluntarily provide to us when you:
<br/><br/>
- Subscribe to our newsletter<br/><br/>
- Fill out a contact form<br/><br/>
- Leave a comment on our blog posts<br/><br/>

This information may include your name, email address, and any other details you choose to provide.<br/><br/>

**Non-Personal Information**  <br/><br/>
We also collect non-personal information automatically when you visit our Site. This includes:
<br/><br/>
- IP address<br/><br/>
- Browser type <br/><br/>
- Operating system <br/><br/>
- Referring URLs <br/><br/>
- Pages visited on our Site <br/><br/>
- Time and date of visits <br/><br/>

**2. How We Use Your Information**<br/><br/>

We use the information we collect to: <br/><br/>

- Provide, operate, and maintain our Site <br/><br/>
- Improve, personalize, and expand our Site <br/><br/>
- Understand and analyze how you use our Site <br/><br/>
- Communicate with you, including responding to your comments and inquiries <br/><br/>
- Send you newsletters, promotional materials, and other information if you have opted in to receive them <br/><br/>

**3. Cookies and Tracking Technologies** <br/><br/>

We use cookies and similar tracking technologies to enhance your experience on our Site. Cookies are small files stored on your device that help us understand how you interact with our Site. You can control cookies through your browser settings. However, disabling cookies may affect your ability to use certain features of our Site.
<br/><br/>
**4. Amazon Affiliate Links**<br/><br/>

Our Site may contain affiliate links to Amazon.com and other third-party websites. When you click on an affiliate link and make a purchase, we may earn a commission at no additional cost to you. These third-party sites have their own privacy policies, and we encourage you to review them before providing any personal information.
<br/><br/>
**5. Data Security**<br/><br/>

We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
<br/><br/>
**6. Your Choices**
<br/><br/>
You have the right to:<br/><br/>

- Access, correct, or delete your personal information<br/><br/>
- Opt out of receiving marketing communications from us <br/><br/>
- Disable cookies through your browser settings <br/><br/>

To exercise these rights, please contact us at [Email :- donateforpoorhungry@gmail.com].<br/><br/>

**7. Children's Privacy** <br/><br/>

Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it promptly.
<br/><br/>
**8. Changes to This Privacy Policy**<br/><br/>

We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
<br/><br/>
**9. Contact Us**<br/><br/>

If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
<br/><br/>
[Tourist Attraction]  
[Address :- Bengaluru]  
[Email :- donateforpoorhungry@gmail.com]  
            </h1></center>
        </div>
    )
}

export default PrivacyPolicy