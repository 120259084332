import Amazon from '..//Assests/Amazon.jpg'
import '../App.css';
import { useNavigate } from 'react-router-dom';
const Sample = () => {
    const navigate = useNavigate()
    function goToPayment(){
        navigate('/Usa')
    }
    function UK1(){
        navigate('/UK')
    }
    function Japan1(){
        navigate('/Japan')
    }
    function India1(){
        navigate('/India')
    }
return (
    <>
                <div>
            <h1>India Amazon Shop <center><button jutify-content="center" className='btn' onClick={India1}>India Amazon Shop Click Me</button></center></h1>
        </div>
        <center><h1>At Least Buy Dog Food From My Amazon Affiliate Link</h1></center>
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Pidegree Dog Food 3kg </h3>
                    
                    <a href="https://amzn.to/3WNGfjo">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Drolls Dog Food 3kg  </h3>
                    
                    <a href="https://amzn.to/3wCqP6T">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3>Purepet Dog Food 10kg </h3>
                    
                    <a href="https://amzn.to/4dNRVsy">Buy Now</a>
                </div>
               
            </div>
    
        </div>
        <div>
    <h1>Japan Amazon Shop <center><button jutify-content="center" className='btn' onClick={Japan1}>Japan Amazon Shop Click Me</button></center></h1>
</div>
<center><h1>At Least Buy Dog Food From My Amazon Affiliate Link</h1></center>
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Nutro Supremo Dog Food 6.6Lb 3Kg </h3>
                    
                    <a href="https://amzn.to/3wHPua6">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Dog Food Grandile 6.4lbs 2.9kg </h3>
                    
                    <a href="https://amzn.to/4apzFCK">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3>Dog Food Japan Pet Food</h3>
                    
                    <a href="https://amzn.to/44PoZfi">Buy Now</a>
                </div>
               
            </div>
    
        </div>
   
</>
)
}
export default Sample