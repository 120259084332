
import '../App.css';


import Web1 from '..//Assests/web1.jpg'
import Cowss  from '..//Assests/cowss.jpg'
import Pigeon from '..//Assests/Pigeon.jpg'
import Eagle from '..//Assests/Eagle.jpg'
import paypal from '..//Assests/paypal.png'
import Sheep from '..//Assests/Sheep.jpg'
import Sample from '../components/Sample';
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

const Home = () => {
  
  return (
    <>
    

    
      <div className='Note'>
        <center>
          <h2>Welcome Amazon Affiliate Links Below Shop Now Please</h2>
        </center>
      </div>
      
      <center>
        <div className='ShopCall'>
          <Sample />
        </div>
      </center>
      
      <div className='headlines'>
        <center>
          <h2>Till Now $700 Received, And We Provided Food, Shelter For Animals And Birds</h2>
        </center>
      </div>
      
      <div className='text1'>
        <center>
          <h1>Donate Now For Wildlife</h1>
          <h1>Scan And Pay By Paypal</h1>
          <br />
          <img src={paypal} alt='Paypal QR Code' />
          <br />
        </center>
      </div>
      
      <div className='anchortag'>
        <center>
          <p>CheckOut Our Work On Youtube</p>
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtVE5AQl2wSGaNQhUn63qPdi'>1. Dogs Videos Click Me</a>
          </button>
          <br />
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtV_zlwR8IWqBknxXUrLIxAK'>2. Cow Videos Click Me</a>
          </button>
          <br />
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtX-EuiKLwOQcRceAr2Ue40y'>3. Pigeons Videos Click Me</a>
          </button>
          <br />
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtWL_CVwJTCp9FXmwE1WM8Yg'>4. Eagle Videos Click Me</a>
          </button>
          <br />
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtXDj_3tCH3IV5rSfmCHsb1Z'>5. Sheep Videos Click Me</a>
          </button>
          <br />
          <button>
            <a href='https://www.youtube.com/playlist?list=PLO2LDzjQ7TtWn8UjdtyZcmbNKMMrRLVBt'>6. WildLife Videos Click Me</a>
          </button>
          <br />
        </center>
      </div>
      
      <div className='text'>
        <center>
          <h2>If You Want To Visit This Places Write To Us At donateforpoorhungry@gmail.com</h2>
          <h2>Donate For WildAnimals & Birds</h2>
        </center>
      </div>
      
      <div className='Row'>
        <div className='W'>
          <img src={Web1} alt='Web1' />
        </div>
        <div className='W'>
          <img src={Cowss} alt='Cowss' />
        </div>
        <div className='W'>
          <img src={Pigeon} alt='Pigeon' />
        </div>
      </div>
      
      <div className='Row'>
        <div className='W'>
          <img src={Eagle} alt='Eagle' />
        </div>
        <div className='W'>
          <img src={Sheep} alt='Sheep' />
        </div>
      </div>
      
      <div className='countries'>
        <center>
          <h2>Other Countries Amazon Shop Link Will Be Updated Daily So Visit This Website Please</h2>
        </center>
      </div>
    </>
  );
};

export default Home;
