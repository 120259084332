import Amazon from '..//Assests/Amazon.jpg'
import '../App.css';
import uk from '..//Assests/uk.png'
import { useNavigate } from 'react-router-dom';
const UK = () => {
    const navigate = useNavigate()
    function Payment(){
        navigate('/Payment')
    }
    return  (
        <>
        <center> <div>
        <br/> <h4>UK Amazon Shop</h4>
         <h4>Jesus Christ Bless You , Please Shop With My Below Amazon Affiliate Link. Also Donate To WildLife <button jutify-content="center" className='btn' onClick={Payment}>WildLife Donation Request Click Me</button></h4>
         
         <br/><img src={uk} alt='error'></img>
     </div></center>
    <div className="container">
        <div className="content-section">
        <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Buy Any Products From Amazon</h3>
                
                <a href="https://amzn.to/3WIFibZ">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> USB 6ft Power Adapter For Iphone 15</h3>
                
                <a href="https://amzn.to/3K8T3sN">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> USB Charger Iphone 14 13 12 11 8 7 6</h3>
                
                <a href="https://amzn.to/3wrDNUZ">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> USB Charger Iphone 14 13 12 11 8 7 6</h3>
                
                <a href="https://amzn.to/3wrDNUZ">Buy Now</a>
            </div>
        </div>

    </div>

    <div className="container">
        <div className="content-section">
        <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Sony TV 83 Inch </h3>
                
                <a href="https://amzn.to/3K6YGbe">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Tcl Tv 32 Inch</h3>
                
                <a href="https://amzn.to/3KdkX6Z">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> RCA Roku TV 32 Inch</h3>
                
                <a href="https://amzn.to/3V5GZPC">Buy Now</a>
            </div>
           
        </div>

    </div>
    <div className="container">
        <div className="content-section">
        <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Hp Laptop </h3>
                
                <a href="https://amzn.to/3WRKHO9">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> MicroSoft Laptop</h3>
                
                <a href="https://amzn.to/4dIw5qd">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Razer Blade Laptop</h3>
                
                <a href="https://amzn.to/453He13">Buy Now</a>
            </div>
           
        </div>

    </div>
    <div className="container">
        <div className="content-section">
        <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Samsung S24 Ultra </h3>
                
                <a href="https://amzn.to/3UGH4HY">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Xiaomi </h3>
                
                <a href="https://amzn.to/3K42Z70">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Google Pixel 8a</h3>
                
                <a href="https://amzn.to/3UMLSM7">Buy Now</a>
            </div>
           
        </div>

    </div>
    <center><h1>At Least Buy Dog Food From My Amazon Affiliate Link</h1></center>
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Dog Food Harringtons 15kg </h3>
                    
                    <a href="https://amzn.to/3yqaXEY">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Dog Food Winalot   </h3>
                    
                    <a href="https://amzn.to/3WKCv1S">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3>Dog Food ArkWrights</h3>
                    
                    <a href="https://amzn.to/4dMTVRs">Buy Now</a>
                </div>
               
            </div>
    
        </div>
    
    </>
    )
    }
    export default UK