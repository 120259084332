import Dogs1 from '..//Assests/Dogs1.jpg'
import { useNavigate } from 'react-router-dom';

function Dogs  () {
    const navigate = useNavigate()
    function goToPayment(){
        navigate('/Payment')
    }

    return (

        

            <div className='container'> 
                <h1 align="center"> Dog Tourist Attraction Details Read Below </h1>
                <p className='p' align="center"> Guided Tour With Tourist Guide Rs,1000/- Click Here , Foriegn Tourist $10/- Virtual Tour Through Video Call </p>
                <center><button jutify-content="center" className='btn' onClick={goToPayment}>Book Tourist Attraction Visit Now</button></center><br/>
                
           
                <center><img src={Dogs1} width={300} height={400} alt="error loading"></img></center> <br/>
              <br/> 
              
              <center><span className='p' >The Fascinating World of Dogs</span></center>
                <p className='p' align="center"> Dogs, often referred to as "man's best friend," have been an integral part of human history for thousands of years. From their role as hunting companions to beloved family pets, dogs have earned a special place in our hearts. Their diverse breeds, unique behaviors, and unwavering loyalty continue to captivate us. Let's delve into the world of dogs and explore what makes them such extraordinary creatures. </p>
                <center><span className='p'>A Brief History of Dogs</span></center>
                <p className='p' align='center'>
                The domestication of dogs is believed to have started over 15,000 years ago, making them one of the first animals to be domesticated by humans. Initially, dogs were used for hunting and protection, assisting early humans in their daily lives. Over time, dogs became valued companions, serving various roles, such as herding, guarding, and even providing therapy and assistance to those in need.
                </p>
                <center><span className='p'>Diverse Breeds, Unique Characteristics</span></center>
                <p className='p' align='center'> 
                One of the most remarkable aspects of dogs is their incredible diversity in size, shape, coat, and behavior. There are over 340 recognized dog breeds worldwide, each with its own distinct characteristics and traits. From the tiny Chihuahua to the towering Great Dane, dogs come in all shapes and sizes.
                <br/>
                Each breed has been selectively bred for specific purposes, resulting in a wide range of physical and behavioral traits. For example, herding breeds like the Border Collie are known for their intelligence and agility, while working breeds like the Siberian Husky are prized for their endurance and strength.
                </p>
                <center><span className='p'>Here is a list of dog breed names:</span></center>
              
                <p className='p' align='center'>
                Indian Pariah Dog, Rampur Greyhound, Mudhol Hound, Rajapalayam, Kombai, Chippiparai, Bakharwal Dog,
                Labrador Retriever, German Shepherd, Golden Retriever, French Bulldog, Bulldog, Beagle, Poodle, Rottweiler, Yorkshire Terrier, Boxer, Dachshund, Siberian Husky, Doberman Pinscher, Great Dane, Australian Shepherd, Shih Tzu, Pembroke Welsh Corgi, Chihuahua, Pug, Boston Terrier, Maltese, Shetland Sheepdog, Cavalier King Charles Spaniel, Miniature Schnauzer, Border Collie, English Springer Spaniel, Australian Cattle Dog, Brittany, Bullmastiff, Weimaraner, Basset Hound, Bernese Mountain Dog, Havanese, Mastiff, Vizsla, Newfoundland, Rhodesian Ridgeback, West Highland White Terrier, Papillon, Collie, Bloodhound, Chinese Shar-Pei, Dalmatian, Samoyed, Alaskan Malamute, Whippet, Akita, Belgian Malinois, Chesapeake Bay Retriever, English Setter
                </p>
              
                <center><span className='p'>The Science Behind Dog Behavior</span></center>
                <p className='p' align='center'>
                Dogs exhibit a wide range of behaviors that are fascinating to observe and study. Their ability to communicate through body language, facial expressions, and vocalizations is a testament to their social nature. Dogs are pack animals, and as such, they have a strong sense of hierarchy and social structure.
                <br/>
                One of the most intriguing aspects of dog behavior is their relationship with humans. Dogs have evolved to understand human cues and emotions, making them highly attuned to our needs and feelings. This ability, known as social cognition, is one of the reasons why dogs have become such beloved companions.
                </p>
                <center><span className='p'>The Benefits of Owning a Dog</span></center>
                <p className='p' align='center'>
                Owning a dog has been shown to have numerous benefits for both physical and mental health. Dogs are known to reduce stress, anxiety, and depression, and can improve overall mood and well-being. The companionship and unconditional love that dogs provide can be incredibly therapeutic.
                <br/>
                Additionally, owning a dog can lead to a more active lifestyle, as dogs require daily exercise and playtime. This can help improve cardiovascular health, increase physical activity, and even lower blood pressure.
                </p>
                <center><span className='p'>Conclusion</span></center>
                <p className='p' align='center'>
                Dogs hold a special place in our lives, offering companionship, loyalty, and endless joy. Their diverse breeds, unique behaviors, and unwavering love continue to enrich our lives in countless ways. Whether as working partners, loyal friends, or beloved family members, dogs have truly earned their title as man's best friend.
                </p>
                

        <br/>
            <p className='p' align="center"> Support Us To Maintain This Tourist Attraction Website, Create Awareness Towards Animals & Birds, Contribute Money Please, Your Small Regular Monthly Or Weekly Money Contribution As Low As A Tea Money Can Help Us Keep This Site Active, So Please Click On Pay Now And Pay Us, Thank You  </p>
        
            <p className='p' align="center"> If You Want To Visit This Place, I Will Share Google Map Location on WhatsApp After You Pay Me Rs,100/- Per Location, Send Me Screen Shot of Payment, My WhatsApp Number On Pay Now Button I Will Send You Location Click Pay Now </p>
        
        <h2 align="center"> 540+ Dogs Videos On Youtube Channel Click Button <button><a className='youtube' href="https://www.youtube.com/playlist?list=PLO2LDzjQ7TtVE5AQl2wSGaNQhUn63qPdi">Go To Youtube</a></button></h2>
           </div>
        

    );
}
export default Dogs