import {NavLink }from 'react-router-dom'
import './Navbar.css';

const Footer = () => {
    return(
        <div>
             <nav className='Navbar'>
            
            <ul>
              
              <li className='a'>
                <NavLink to="/TermsAndCondition">TermsAndCondition</NavLink>

              </li>
              <li className='b'>
                <NavLink to="/PrivacyPolicy">PrivacyPolicy</NavLink>
              </li>

               <li className='c'>
                <NavLink to="/Disclaimer"> Disclaimer</NavLink>
              </li>
              
              <li className='c'>
                <NavLink to="/Affiliate">Affilate</NavLink>
              </li>
             
               
              
              
            </ul>
        </nav>
       
        </div>
    )
}
export default Footer