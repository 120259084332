const About = () => {
    return(
        <div>
                    <h1 className="p" align='center'>
<br/>
Welcome to [TouristAttraction.shop]!<br/>
<br/>
We are dedicated to providing you with the best product recommendations and reviews to help you make informed purchasing decisions.<br/>
<br/>
Our team of experts carefully researches and tests a wide range of products to ensure that we only recommend the highest quality items.<br/>
<br/>
Whether you’re looking for the latest gadgets, home essentials, or must-have accessories, we’ve got you covered.<br/>
<br/>
We partner with Amazon to bring you a diverse selection of products at competitive prices.<br/>
<br/>
By clicking on our affiliate links and making a purchase, you help support our website at no extra cost to you.<br/>
<br/>
Thank you for visiting [TouristAttraction]!<br/>
<br/>
If you have any questions or need assistance, feel free to reach out to us.<br/>
<br/>
Happy shopping!<br/>
<br/>
The [TouristAttraction] Team<br/>
                   </h1>
        </div>
    )
}

export default About