
import { Outlet } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';



function App() {

  return (
    
    <div>

      <header>
        <h2 align='center'> Welcome To Tourist Attraction</h2>
        <Navbar/>
        
  
        </header>
      
      <main>
        <Outlet/>
        
      </main>

      <footer>
        <Footer/>
      </footer>

    </div>
    
  );
}

export default App;
