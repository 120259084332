import Amazon from '..//Assests/Amazon.jpg'
import '../App.css';
import india from '..//Assests/india.png'
import { useNavigate } from 'react-router-dom';
const India = () => {
    const navigate = useNavigate()
    function Payment(){
        navigate('/Payment')
    }
    return  (
        <>
        <center> <div>
       <br/> <h4> India Amazon Shop</h4>
        <h4> Sanatan Hindu Gods, Jain Gods, Muslim Gods, Sikh Gods, Buddhism Gods, Will Bless You , Please Shop With My Below Amazon Affiliate Link. Also Donate To WildLife <button jutify-content="center" className='btn' onClick={Payment}>WildLife Donation Request Click Me</button></h4>
        
        <br/><img src={india} alt='error'></img>
    </div></center>
        
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Buy Any Products From Amazon </h3>
                    
                    <a href="https://amzn.to/4bFOwdb">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Bag Blue 33L waterproof Bag School College With laptop compartment + Rain Cover With Pouch </h3>
                    
                    <a href="https://amzn.to/44LnZJl">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3>Hero Vida Scooter </h3>
                    
                    <a href="https://amzn.to/3WGRABC">Buy Now</a>
                </div>
               
            </div>
    
        </div>
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Revolt Electric Bike </h3>
                    
                    <a href="https://amzn.to/453J5mx">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Hero Vida V1 Pro Scooter </h3>
                    
                    <a href="https://amzn.to/3V6kiuD">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3>Chetak Electric Scooter </h3>
                    
                    <a href="https://amzn.to/3K44guO">Buy Now</a>
                </div>
               
            </div>
    
        </div>
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Samsung ZFold 5 </h3>
                    
                    <a href="https://amzn.to/3wMECaQ">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Samsung S24 Ultra Mobile </h3>
                    
                    <a href="https://amzn.to/3UPy6YO">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3>Iphone 15Pro Max </h3>
                    
                    <a href="https://amzn.to/3QRpfVp">Buy Now</a>
                </div>
               
            </div>
    
        </div>
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> MSI Laptop </h3>
                    
                    <a href="https://amzn.to/3yrPsUo">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Dell Alien Ware Laptop </h3>
                    
                    <a href="https://amzn.to/3wHOrqG">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3>Lenova Legion Laptop </h3>
                    
                    <a href="https://amzn.to/4ayEQ3u">Buy Now</a>
                </div>
               
            </div>
    
        </div>
        <center><h1>At Least Buy Dog Food From My Amazon Affiliate Link</h1></center>
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Pidegree Dog Food 3kg </h3>
                    
                    <a href="https://amzn.to/3WNGfjo">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Drolls Dog Food 3kg  </h3>
                    
                    <a href="https://amzn.to/3wCqP6T">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3>Purepet Dog Food 10kg </h3>
                    
                    <a href="https://amzn.to/4dNRVsy">Buy Now</a>
                </div>
               
            </div>
    
        </div>
        
        </>
    )
    }
    export default India