import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import Home from './Pages/Home';
import Dogs from './Pages/Dogs';
import Pigeons from './Pages/Pigeons';
import Eagles from './Pages/Eagles';
import Cows from './Pages/Cows';
import Goat from './Pages/Goat';
import Payment from './Pages/Payment';
import Contact from './Pages/Contact';
import Bus from './Pages/Bus';
import About from './Pages/About';
import UK from './Pages/UK';
import Usa from './Pages/Usa';
import Japan from './Pages/Japan';
import India from './Pages/India';
// import Page404 from './Pages/Page404';

import PrivacyPolicy from './Pages/PrivacyPolicy';
import Disclaimer from './Pages/Disclaimer';
import TermsAndCondition from './Pages/TermsAndCondition';
import Affiliate from './Pages/Affiliate';

import FileUpload from './Pages/FileUpload';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App/>}>
        <Route index element={<Home/>}/>
        <Route path="Dogs" element={<Dogs/>}/>
        <Route path="Pigeons" element={<Pigeons/>}/>
        <Route path="Eagles" element={<Eagles/>}/>
        <Route path="Cows" element={<Cows/>}/>
        <Route path="Goat" element={<Goat/>}/>
        <Route path="Payment" element={<Payment/>}/>
        <Route path="Contact" element={<Contact/>}/>
        <Route path="Bus" element={<Bus/>}/>
        <Route path="About" element={<About/>}/>
        <Route path="UK" element={<UK/>}/>
        <Route path="Usa" element={<Usa/>}/>
        <Route path="Japan" element={<Japan/>}/>
        <Route path="India" element={<India/>}/>
        <Route path="TermsAndCondition" element={<TermsAndCondition/>}/>
        <Route path="PrivacyPolicy" element={<PrivacyPolicy/>}/>
        <Route path="Disclaimer" element={<Disclaimer/>}/>
        <Route path="Affiliate" element={<Affiliate/>}/>
        <Route path="FileUpload" element={<FileUpload/>}/>
        <Route path="/*" element={<Navigate to="/" />}/>
       </Route>
  )
)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
