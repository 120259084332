import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // For navigation
import '../Pages/FileUpload.css'; // Import the CSS file
// import PTM from '../Assests/PTM.jpg'; // Adjust the path as necessary
// import PP from '../Assests/PP.png'; // Adjust the path as necessary

const FileUpload = () => {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    location: '',
    price: '',
    description: '',
    transactionId: '',
    photo: null,
  });

  const [uploadStatus, setUploadStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL || 'https://touristattraction.shop';
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      setFormData((prev) => ({
        ...prev,
        photo: file,
      }));
    } else {
      setUploadStatus('Please upload a valid image (JPEG/PNG).');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!formData.photo) {
      setUploadStatus('Please upload a photo.');
      setIsLoading(false);
      return;
    }

    try {
      const fileData = new FormData();
      fileData.append('photo', formData.photo);

      const uploadRes = await axios.post(`${apiUrl}/server/uploads`, fileData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (uploadRes.data.success) {
        const photoFilename = uploadRes.data.file;
        const postData = { ...formData, photo: photoFilename };

        const postRes = await axios.post(`${apiUrl}/server/posts`, postData);
        if (postRes.data.success) {
          setUploadStatus('Post submitted successfully!');
          setIsLoading(false);
          navigate('/');
        } else {
          setUploadStatus('Error submitting post.');
          setIsLoading(false);
        }
      } else {
        setUploadStatus('Photo upload failed.');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error during upload:', error);
      setUploadStatus('An error occurred during upload.');
      setIsLoading(false);
    }
  };

  return (
    <div className="file-upload">
      <h2>Submit a Post</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Number</label>
          <input
            type="text"
            name="number"
            value={formData.number}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Location</label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Price</label>
          <input
            type="text"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Transaction ID</label>
          <input
            type="text"
            name="transactionId"
            value={formData.transactionId}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Upload Photo</label>
          <input
            type="file"
            name="photo"
            accept="image/jpeg,image/png"
            onChange={handleFileChange}
            required
          />
        </div>

        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Submitting...' : 'Submit Post'}
        </button>
      </form>

      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default FileUpload;
