import Bus1 from '..//Assests/Bus1.jpg'
import '../App.css';

import { useNavigate } from 'react-router-dom';
const Bus = () => {

    const navigate = useNavigate()
    function goToPayment(){
        navigate('/Payment')
    }

    return (
        <body>

        <div>
        <h1 align="center"> Bus Tourist Attraction Details Read Below </h1>
        <p className='p' align="center"> Guided Tour With Tourist Guide Rs,1000/- Click Here , Foriegn Tourist $10/- Virtual Tour Through Video Call </p>
                <center><button jutify-content="center" className='btn' onClick={goToPayment}>Book Tourist Attraction Visit Now</button></center><br/>
               

        
         <br/><center><img src={Bus1} width={400} height={500}  alt="error loading"></img></center><br/>
        <p className='p' align="center">A bus is a road vehicle that carries significantly more passengers than an average car or van, but less than the average rail transport. It is most commonly used in public transport, but is also in use for charter purposes, or through private ownership. Although the average bus carries between 30 and 100 passengers, some buses have a capacity of up to 300 passengers. The most common type is the single-deck rigid bus, with double-decker and articulated buses carrying larger loads, and midibuses and minibuses carrying smaller loads. Coaches are used for longer-distance services. Many types of buses, such as city transit buses and inter-city coaches, charge a fare. Other types, such as elementary or secondary school buses or shuttle buses within a post-secondary education campus, are free. In many jurisdictions, bus drivers require a special large vehicle licence above and beyond a regular driving licence.
<br/><br/>
Buses may be used for scheduled bus transport, scheduled coach transport, school transport, private hire, or tourism; promotional buses may be used for political campaigns and others are privately operated for a wide range of purposes, including rock and pop band tour vehicles.
<br/><br/>
Horse-drawn buses were used from the 1820s, followed by steam buses in the 1830s, and electric trolleybuses in 1882. The first internal combustion engine buses, or motor buses, were used in 1895. Recently, interest has been growing in hybrid electric buses, fuel cell buses, and electric buses, as well as buses powered by compressed natural gas or biodiesel. As of the 2010s, bus manufacturing is increasingly globalised, with the same designs appearing around the world. 
        </p>
        <p className='p' align="center">If You Want To Visit This Place, I Will Share Google Map Location on WhatsApp After You Pay Me Rs,100/- Per Location, Send Me Screen Shot of Payment, My WhatsApp Number On Pay Now Button I Will Send You Location Click Pay Now</p>
        <center><button jutify-content="center" className='btn' onClick={goToPayment}>Pay Now</button></center><br/>
        <p className='p' align="center"> Support Us To Maintain This Tourist Attraction Website, Create Awareness Towards Animals & Birds, Contribute Money Please, Your Small Regular Monthly Or Weekly Money Contribution As Low As A Tea Money Can Help Us Keep This Site Active, So Please Click On Pay Now And Pay Us, Thank You  </p>
        
        <h2 align="center"> 10000+ Bus Videos On Youtube Channel Click Go To Youtube <button className='btn'><a className='youtube' href="https://www.youtube.com/playlist?list=PLO2LDzjQ7TtWnC7i0EvJ-1mvo_omb2CCC">Go To Youtube</a></button></h2>
        
               </div>
        
        </body>
       
    )
}
export default Bus