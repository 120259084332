import cowss from '..//Assests/cowss.jpg'
import { useNavigate } from 'react-router-dom';

const Cows = () => {
    const navigate = useNavigate()
    function goToPayment(){
        navigate('/Payment')
    }
    return (
        

        <div className='container'>
            <h1 align="center">  Cows Tourist Attraction Details Read Below </h1>
            <p className='p' align="center"> Guided Tour With Tourist Guide Rs,1000/- Click Here , Foriegn Tourist $10/- Virtual Tour Through Video Call </p>
                <center><button jutify-content="center" className='btn' onClick={goToPayment}>Book Tourist Attraction Visit Now</button></center><br/>
               
        
        
         <br/><center><img src={cowss} width={300} height={400} alt="error loading"></img></center>
        <p className='p' align="center"> Cattle are large, domesticated, bovid ungulates widely kept as livestock. They are prominent modern members of the subfamily Bovinae and the most widespread species of the genus Bos. Mature female cattle are called cows and mature male cattle are bulls. Young female cattle are called heifers, young male cattle are oxen or bullocks, and castrated male cattle are known as steers.
<br/><br/>
Cattle are commonly raised for meat, for dairy products, and for leather. As draft animals, they pull carts and farm implements. In India, cattle are sacred animals within Hinduism, and may not be killed. Small breeds such as the miniature Zebu are kept as pets.
<br/><br/>
Taurine cattle are widely distributed across Europe and temperate areas of Asia, the Americas, and Australia. Zebus are found mainly in India and tropical areas of Asia, America, and Australia. Sanga cattle are found primarily in sub-Saharan Africa. These types, sometimes classified as separate species or subspecies, are further divided into over 1,000 recognized breeds.
<br/><br/>
Around 10,500 years ago, taurine cattle were domesticated from wild aurochs progenitors in central Anatolia, the Levant and Western Iran. A separate domestication event occurred in the Indian subcontinent, which gave rise to zebu. There were over 940 million cattle in the world by 2022. Cattle are responsible for around 7% of global greenhouse gas emissions. They were one of the first domesticated animals to have a fully-mapped genome. </p>
        <p className='p' align="center"> Support Us To Maintain This Tourist Attraction Website, Create Awareness Towards Animals & Birds, Contribute Money Please, Your Small Regular Monthly Or Weekly Money Contribution As Low As A Tea Money Can Help Us Keep This Site Active, So Please Click On Pay Now And Pay Us, Thank You  </p>
        
        <p className='p' align="center"> If You Want To Visit This Place, I Will Share Google Map Location on WhatsApp After You Pay Me Rs,100/- Per Location, Send Me Screen Shot of Payment, My WhatsApp Number On Pay Now Button I Will Send You Location Click Pay Now </p>
       
        <h2 align="center"> 190+ Cow Videos On Youtube Channel Link Below </h2>
       <center><button>
        <a className='youtube' href="https://www.youtube.com/playlist?list=PLO2LDzjQ7TtV_zlwR8IWqBknxXUrLIxAK">Go To youtube</a>
        </button></center> 
        
       
          </div>
        
       
    )
}
export default Cows