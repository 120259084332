const TermsAndCondition = () => {
    return(
        <div>
          <center>  <h1>
            <br/>
**Terms and Conditions**
<br/>
<br/>
**Last Updated:[26-07-2024]**
<br/><br/>
Welcome to [TouristAttraction] (the "Site"). By accessing or using our Site, you agree to comply with and be bound by the following Terms and Conditions (the "Terms"). Please read these Terms carefully before using our Site. If you do not agree with these Terms, please do not use our Site.
<br/> <br/>
**1. Acceptance of Terms**
<br/><br/>
By accessing or using our Site, you agree to these Terms and our Privacy Policy, which is incorporated by reference. We may update these Terms from time to time, and your continued use of the Site signifies your acceptance of any changes.
<br/><br/>
**2. Use of the Site**
<br/><br/>
You agree to use our Site only for lawful purposes and in accordance with these Terms. You must not use our Site:
<br/><br/>
- In any way that violates any applicable local, state, national, or international law or regulation.<br/><br/>
- To exploit, harm, or attempt to exploit or harm others, including minors.<br/><br/>
- To transmit or procure the sending of any advertising or promotional material without our prior written consent.<br/><br/>
- To engage in any automated data collection activities, such as scraping or harvesting, without our express permission.
<br/><br/>
**3. Intellectual Property**
<br/><br/>
All content on our Site, including but not limited to text, graphics, logos, and images, is the property of [TouristAttraction] or its licensors and is protected by intellectual property laws. You may not use, reproduce, distribute, or create derivative works from any content on our Site without our express written permission.
<br/><br/>
**4. Amazon Affiliate Links**
<br/><br/>
Our Site participates in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com. We may earn a commission if you click on an affiliate link and make a purchase. The presence of affiliate links does not imply endorsement of the linked product or service.
<br/><br/>
**5. Disclaimer of Warranties**
<br/><br/>
Our Site and all content, products, and services provided through it are offered on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, regarding the operation or availability of our Site or the information, content, materials, or products included on it. To the fullest extent permitted by law, we disclaim all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
<br/><br/>
**6. Limitation of Liability**
<br/><br/>
To the fullest extent permitted by law, [Tourist Attraction] will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, or other intangible losses, arising from or related to your use of our Site or any content or services provided through it.
<br/><br/>
**7. Third-Party Links**
<br/><br/>
Our Site may contain links to third-party websites that are not owned or controlled by [Tourist Attraction]. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites. You acknowledge and agree that [Your Business Name] is not responsible or liable, directly or indirectly, for any damage or loss caused by or in connection with the use of or reliance on any third-party content, goods, or services.
<br/><br/>
**8. Termination**
<br/><br/>
We reserve the right to terminate or suspend your access to our Site, without prior notice or liability, for any reason, including if you breach these Terms.
<br/><br/>
**9. Governing Law**
<br/><br/>
These Terms are governed by and construed in accordance with the laws of [Karnataka/India], without regard to its conflict of law principles. Any legal action or proceeding arising out of or related to these Terms or your use of our Site will be brought exclusively in the courts located in [Karnataka/India].
<br/><br/>
**10. Contact Information**
<br/><br/>
If you have any questions or concerns about these Terms, please contact us at:
<br/><br/>
[Tourist Attraction]  
[Address :- Bengaluru]  
[Email :- donateforpoorhungry@gmail.com]
            </h1></center>
        </div>
    )
}
export default TermsAndCondition