import Amazon from '..//Assests/Amazon.jpg'
import '../App.css';
import usa from '..//Assests/usa.png'
import { useNavigate } from 'react-router-dom';
const Usa = () => {
    const navigate = useNavigate()
    function Payment(){
        navigate('/Payment')
    }
return (
    <>
   <center> <div>
       <br/> <h4>Usa Amazon Shop</h4>
        <h4>Jesus Christ Bless You , Please Shop With My Below Amazon Affiliate Link. Also Donate To WildLife <button jutify-content="center" className='btn' onClick={Payment}>WildLife Donation Request Click Me</button></h4>
        
        <br/><img src={usa} alt='error'></img>
    </div></center>
    <div className="container">
        <div className="content-section">
        <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Buy Any Products Form Amazon</h3>
                
                <a href="https://amzn.to/4dJAj0E">Buy Now</a>
            </div>

            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Play Station 5 </h3>
                
                <a href="https://amzn.to/4dJOC5u">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Beats Studio Earbuds</h3>
                
                <a href="https://amzn.to/4anxEXy">Buy Now</a>
            </div>
           
            
        </div>

    </div>

<div className="container">
<div className="content-section">
<div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> WaterProof Bed Sofa Cover </h3>
                
                <a href="https://amzn.to/3wzCljm">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Sony PlayStation 5 </h3>
                
                <a href="https://amzn.to/3V2O0AD">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> PlayStation 4 </h3>
                
                <a href="https://amzn.to/3K89FRw">Buy Now</a>
            </div>
            
    </div>
    </div>
    <div className="container">
<div className="content-section">
<div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Samsung Galaxy S23 FE Mobile</h3>
                
                <a href="https://amzn.to/3Ka7wVD">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Samsung S24 Mobile</h3>
                
                <a href="https://amzn.to/3wFEJoD">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Samsung S24 Ultra Mobile </h3>
                
                <a href="https://amzn.to/3V7NsJT">Buy Now</a>
            </div>
            
    </div>
    </div>
    <div className="container">
<div className="content-section">
<div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Mavark Laptop 128gb Ram 8tb ssd</h3>
                
                <a href="https://amzn.to/3wQUhpA">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Dell Laptop 128gb Ram </h3>
                
                <a href="https://amzn.to/3UIEWj3">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> MSI Laptop</h3>
                
                <a href="https://amzn.to/4bCYYmp">Buy Now</a>
            </div>
            
    </div>
    </div>
    <div className="container">
<div className="content-section">
<div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Amazon TV 75 inch</h3>
                
                <a href="https://amzn.to/4apjx4i">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Amazon Tv 43 inch </h3>
                
                <a href="https://amzn.to/4bn8vxx">Buy Now</a>
            </div>
            <div className="card">
                <img src={Amazon} alt="error"></img>
                <h3> Amazon tv 55 inch</h3>
                
                <a href="https://amzn.to/4dI3otx">Buy Now</a>
            </div>
            
    </div>
    </div>
    <center><h1>At Least Buy Dog Food From My Amazon Affiliate Link</h1></center>
        <div className="container">
            <div className="content-section">
            <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Pidegree Dog Food 30lb Bag </h3>
                    
                    <a href="https://amzn.to/3yqaXEY">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3> Dog Food    </h3>
                    
                    <a href="https://amzn.to/4bFFU6q">Buy Now</a>
                </div>
                <div className="card">
                    <img src={Amazon} alt="error"></img>
                    <h3>Dog Food Amazon Brand </h3>
                    
                    <a href="https://amzn.to/3wQXZzw">Buy Now</a>
                </div>
               
            </div>
    
        </div>
   
    
</>

)
}
export default Usa