import Eagle from '../Assests/Eagle.jpg';
import { useNavigate } from 'react-router-dom';

const Eagles = () => {
    const navigate = useNavigate()
    function goToPayment(){
        navigate('/Payment')
    }
    return (
        

        <div className='container'>
            <h1 align="center"> Eagles Tourist Attraction Details Read Below </h1>

            <p className='p' align="center"> Guided Tour With Tourist Guide Rs,1000/- Click Here , Foriegn Tourist $10/- Virtual Tour Through Video Call </p>
                <center><button jutify-content="center" className='btn' onClick={goToPayment}>Book Tourist Attraction Visit Now</button></center><br/>
               
        
        <center>
           <img src={Eagle} width={300} height={400} alt="error loading"></img>   </center>
           <p className='p' align="center"> Eagle is the common name for the golden eagle, bald eagle, and other birds of prey in the family Accipitridae. Eagles belong to several groups of genera, some of which are closely related. True eagles comprise the genus Aquila. Most of the 68 species of eagles are from Eurasia and Africa. Outside this area, just 14 species can be found—two in North America, nine in Central and South America, and three in Australia.

Eagles are not a natural group but denote essentially any kind of bird of prey large enough to hunt sizeable about 50 cm long or more overall vertebrates.

</p>
        <br/>
       <p className='p' align="center"> Support Us To Maintain This Tourist Attraction Website, Create Awareness Towards Animals & Birds, Contribute Money Please, Your Small Regular Monthly Or Weekly Money Contribution As Low As A Tea Money Can Help Us Keep This Site Active, So Please Click On Pay Now And Pay Us, Thank You  </p>
        
        <p className='p' align="center"> If You Want To Visit This Place, I Will Share Google Map Location on WhatsApp After You Pay Me Rs,100/- Per Location, Send Me Screen Shot of Payment, My WhatsApp Number On Pay Now Button I Will Send You Location Click Pay Now </p>
       
        <h2 align="center"> 1000+ Eagles Videos On Youtube Channel Link Below </h2>
       <center><button>
        <a className='youtube' href="https://www.youtube.com/playlist?list=PLO2LDzjQ7TtWL_CVwJTCp9FXmwE1WM8Yg">Go To youtube</a>
        </button> </center><br/><br/>
        
        </div>
        
       
    )
}
export default Eagles