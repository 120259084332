const Affiliate = () => {
    return(
        <div>
          <center>  <h1>
            <br/><br/>
**Affiliate Disclosure**
<br/><br/>
**Last Updated: [26-07-2024]**
<br/><br/>
At [TouristAttraction.shop] (the "Site"), we are committed to transparency and integrity in our affiliate relationships. This Affiliate Disclosure explains how we earn commissions and the nature of our affiliate partnerships.
<br/><br/>
**1. Affiliate Relationships**
<br/><br/>
[TouristAttraction.shop] participates in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com. As an Amazon Associate, we earn from qualifying purchases made through links on our Site. 
<br/><br/>
**2. How It Works**
<br/><br/>
When you click on a link to Amazon or other affiliate sites on our Site and make a purchase, we may earn a commission. This commission is earned at no additional cost to you. Our goal is to provide useful and relevant content to help you make informed decisions, and the commissions we earn help support the operation of our Site.
<br/><br/>
**3. No Additional Cost to You**
<br/><br/>
Using our affiliate links does not result in any extra cost to you. The price of the products or services you purchase remains the same whether you use our affiliate links or go directly to the merchant's website.
<br/><br/>
**4. Recommendations and Reviews**
<br/><br/>
Our content, including recommendations and reviews, is based on our opinions and experiences. We strive to provide accurate and honest information, but please note that our opinions are influenced by our affiliate partnerships. We do not guarantee the effectiveness or quality of any products or services mentioned on our Site.
<br/><br/>
**5. Transparency and Integrity**
<br/><br/>
We are committed to maintaining the integrity of our Site and providing clear and honest information to our visitors. Our primary goal is to offer value and support your purchasing decisions. Your trust is important to us, and we appreciate your support.
<br/><br/>
**6. Changes to the Disclosure**
<br/><br/>
We may update this Affiliate Disclosure from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this disclosure periodically to stay informed about our affiliate practices.
<br/><br/>
**7. Contact Us**
<br/><br/>
If you have any questions or concerns about our affiliate relationships or this disclosure, please contact us at:
<br/><br/>
[Tourist Attraction]  
[Address :- Bengaluru]  
[Email :- donateforpoorhungry@gmail.com]           
  </h1> </center>
        </div>
    )
}
export default Affiliate