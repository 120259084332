import Pigeon from '..//Assests/Pigeon.jpg'
import '../App.css';

import { useNavigate } from 'react-router-dom';
const Pigeons = () => {

    const navigate = useNavigate()
    function goToPayment(){
        navigate('/Payment')
    }

    return (
        

        <div>
        <h1 align="center"> Pigeons Tourist Attraction Details Read Below </h1>

        <p className='p' align="center"> Guided Tour With Tourist Guide Rs,1000/- Click Here , Foriegn Tourist $10/- Virtual Tour Through Video Call </p>
                <center><button jutify-content="center" className='btn' onClick={goToPayment}>Book Tourist Attraction Visit Now</button></center><br/>
                
         <br/><center><img src={Pigeon} width={400} height={500}  alt="error loading"></img></center><br/>
         <p className='p' align="center"> Domestic Pigeons have held historical importance to humans as food, pets, holy animals, and messengers, Racing Sports , Exhibition breeds.<br/>

         <label>
          <br/> <p> Fancy Pigeons Breeds Varities Below:</p>
         <ul> 
            <li> Archangel Pigeon </li> 
            <li> Old Dutch Capuchine Pigeon </li>
            <li> Lucerne Gold Collar Pigeon</li>
            <li> Oriental Frill Pigeon </li>
            <li> Old German Owl Pigeon  Etc..</li>

         </ul>
         </label>
        Feral pigeons also called city doves, city pigeons, or street pigeons, are descendants of domestic pigeons that have returned to the wild. The domestic pigeon was originally bred from the wild rock dove, which naturally inhabits sea-cliffs and mountains. Rock, domestic, and feral pigeons are all the same species and will readily interbreed. Feral pigeons find the ledges of buildings to be a substitute for sea cliffs, have become adapted to urban life, and are abundant in towns and cities throughout much of the world.

       <br/> Owing to their capacity to create large amounts of excrement and be an occasional disease vector to humans combined with crop and property damage, pigeons are largely considered a nuisance and an invasive species, often disparagingly referred to as "rats with wings". Actions are taken in many municipalities to lower their numbers or completely eradicate them.
        </p>

        <p>  </p>

        <p className='p' align="center"> If You Want To Visit This Place, I Will Share Google Map Location on WhatsApp After You Pay Me Rs,100/- Per Location, Send Me Screen Shot of Payment, My WhatsApp Number On Pay Now Button I Will Send You Location Click Pay Now </p>
        
         <p className='p' align="center"> Support Us To Maintain This Tourist Attraction Website, Create Awareness Towards Animals & Birds, Contribute Money Please, Your Small Regular Monthly Or Weekly Money Contribution As Low As A Tea Money Can Help Us Keep This Site Active, So Please Click On Pay Now And Pay Us, Thank You  </p>
        
         <h2 align="center"> 10000+ Pigeons Videos On Youtube Channel Click Go To Youtube <button className='btn'><a className='youtube' href="https://www.youtube.com/playlist?list=PLO2LDzjQ7TtX-EuiKLwOQcRceAr2Ue40y">Go To Youtube</a></button></h2>
        <br/>
       


        </div>
        
        
       
    )
}
export default Pigeons